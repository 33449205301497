/* eslint-disable comma-dangle */
/*
--------------------------------------------------------------------------
  SLIDING JUMBO TEXT ON MOBILE
--------------------------------------------------------------------------
*/

import { isDebug, body } from "../utils"

export default class {
   constructor (speed = 4) {
      if (isDebug) console.log("sliding:constructor")
      this.enabled = false
      this.elements = document.querySelectorAll(".parallax-track")
      this.speed = speed
      this.animationId = null
   }

   init () {
      if (isDebug) console.log("sliding:init")

      this.elements = document.querySelectorAll(".parallax-track")
      this.startAnimation()

      // window.addEventListener("resize", this.handleResize.bind(this))
      // window.dispatchEvent(new Event("resize"))
   }

   // handleResize (e) {
   //    if (isDebug) console.log("sliding:handleResize")
   //    if (this.enabled && body.classList.contains("has-scroll-smooth")) {
   //       this.enabled = false
   //       this.stopAnimation()
   //    } else if (!this.enabled && !body.classList.contains("has-scroll-smooth")) {
   //       this.enabled = true
   //       this.startAnimation()
   //    }
   // }

   handleTransitionEnd (e) {
      if (isDebug) console.log("sliding:handleTransitionEnd")

      this.resetTransition(e.target)
   }

   updateAnimation () {
      // if (isDebug) console.log("sliding:updateAnimation")
      this.elements.forEach((element) => {
         let currentPosition = parseFloat(getComputedStyle(element).getPropertyValue("transform").split(",")[4]) || 0
         currentPosition -= this.speed

         if (currentPosition <= -element.clientWidth / 3) {
            currentPosition = 0
         }

         element.style.transform = `translateX(${currentPosition}px)`
      })

      this.animationId = requestAnimationFrame(() => this.updateAnimation())
   }

   startAnimation () {
      if (isDebug) console.log("sliding:startAnimation")
      this.updateAnimation()
   }

   stopAnimation () {
      cancelAnimationFrame(this.animationId)
   }

   unload () {
      if (isDebug) console.log("sliding:unload")
      this.stopAnimation()

      // window.removeEventListener("transitionend", this.handleResize)
   }
}
