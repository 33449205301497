/* eslint-disable comma-dangle */
/*
--------------------------------------------------------------------------
  TEAM HOVER INFO
--------------------------------------------------------------------------
*/

import { isDebug } from "../utils"

export default class {
   init () {
      if (isDebug) console.log("team:init")

      this.zIndex = 0

      this.section = document.getElementById("team")
      this.cards = this.section.querySelectorAll("[data-fn-individual]")
      this.popups = this.section.querySelectorAll("[data-fn-individual-popup]")
      this.currentIndividual = null

      this.initTeam()
   }

   initTeam () {
      if (isDebug) console.log("team:initTeam")

      this.cards = this.cards ?? document.querySelectorAll("[data-fn-individual]")
      for (let i = 0; i < this.cards.length; i++) {
         this.cards[i].addEventListener("mousemove", this.handleMouseMove.bind(this))
         this.cards[i].addEventListener("wheel", this.handleMouseMove.bind(this), { passive: true })
      }
   }

   handleMouseMove (e) {
      const individual = e.target.closest("[data-fn-individual]")

      if (individual) {
         if (individual !== this.currentIndividual || this.currentIndividual === null) {
            if (!this.zIndex) this.zIndex = 1

            this.currentIndividual = individual
            this.currentPopup = individual.querySelector("[data-fn-individual-popup]")
            individual.style.zIndex = this.zIndex++
         }

         if (this.currentPopup) {
            if (window.innerWidth > 768 && window.matchMedia("(hover: hover)").matches) {
               // Position the popup on mouse pos
               const individualPos = individual.getBoundingClientRect()
               const left = Math.floor(e.clientX - individualPos.left)
               const top = Math.floor(e.clientY - individualPos.top)

               // Reverse the content if mouseX is past the middle of the screen
               if (e.clientX > window.innerWidth / 2) {
                  individual.setAttribute("data-is-flipped", "true")
               } else {
                  individual.removeAttribute("data-is-flipped")
               }

               const currentPopupTransform = this.currentPopup.style.transform
               const newPopupTransform = "translate(" + left + "px, calc(" + top + "px - var(--spacing) * 0.75))"
               if (currentPopupTransform !== newPopupTransform) this.currentPopup.style.transform = newPopupTransform
            } else {
               if (this.currentPopup.style.transform !== "") this.currentPopup.style.transform = ""
               this.currentPopup.style.transform = ""
            }
         }
      }
   }

   unload () {
      if (isDebug) console.log("team:unload")

      for (let i = 0; i < this.cards.length; i++) {
         this.cards[i].removeEventListener("mousemove")
         this.cards[i].removeEventListener("wheel")
      }
   }
}
