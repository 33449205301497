/* eslint-disable comma-dangle */
/*
--------------------------------------------------------------------------
  CUSTOM CONTENT POPUPS
--------------------------------------------------------------------------
*/

import { isDebug, body } from "../utils"

export default class {
   init () {
      if (isDebug) console.log("popup:init")

      this.overlay = document.getElementById("popup-overlay")
      this.listing = document.getElementById("listing")
      this.popup = document.getElementById("popup")

      this.initPopups()

      // Pagination

      // Reinitialize when the content is dynamically changed with filters
      // const observer = new MutationObserver(this.initPopups)

      // define what element should be observed by the observer
      // and what types of mutations trigger the callback
      // observer.observe(this.listing, {
      //    childList: true
      // })
   }

   initPopups () {
      this.closers = document.querySelectorAll("button[data-fn-popup-close]")
      this.triggers = document.querySelectorAll("button[data-fn-popup]")
      // OPEN POPUP AND CONTENT
      for (let i = 0; i < this.triggers.length; i++) {
         this.triggers[i].addEventListener("click", this.openPopup.bind(this))
      }

      // CLOSE POPUP AND CONTENT
      for (let i = 0; i < this.closers.length; i++) {
         this.closers[i].addEventListener("click", this.closePopup.bind(this))
      }

      this.overlay.removeEventListener("click", this.closePopup)
      this.overlay.addEventListener("click", this.closePopup.bind(this))
   }

   openPopup (e) {
      if (isDebug) console.log("popup:openPopup")
      const panel = document.querySelector("div[data-fn-popup=" + e.target.getAttribute("data-fn-popup") + "]")
      window.dispatchEvent(new Event("scrollPause"))

      setTimeout(() => {
         body.setAttribute("data-fn-body-popup-is-open", true)
         this.popup.setAttribute("data-fn-popup-is-open", true)
         panel.setAttribute("data-fn-is-open", true)
      }, 10)
   }

   closePopup () {
      if (isDebug) console.log("popup:closePopup")
      const panel = document.querySelector("[data-fn-popup][data-fn-is-open]")

      body.removeAttribute("data-fn-body-popup-is-open")

      this.popup.removeAttribute("data-fn-popup-is-open")
      panel.removeAttribute("data-fn-is-open")

      this.overlay.removeEventListener("transitionend", this.resetOverlay)
      this.overlay.addEventListener("transitionend", this.resetOverlay.bind(this))

      window.dispatchEvent(new Event("scrollResume"))
   }

   resetOverlay () {
      if (isDebug) console.log("popup:resetOverlay")
      this.overlay.removeEventListener("transitionend", this.resetOverlay)
   }

   destroy () {
      if (isDebug) console.log("popup:destroy")

      if (typeof this.triggers.removeEventListener === "function") { this.triggers.removeEventListener("click") }
      if (typeof this.closers.removeEventListener === "function") this.closers.removeEventListener("click", this.closePopup)
   }
}
