/* eslint-disable comma-dangle */
/*
--------------------------------------------------------------------------
  JOB APPLICATION POPUPS
--------------------------------------------------------------------------
*/

import { isDebug } from "../utils"

export default class {
   constructor () {
      if (isDebug) console.log("job:constructor")

      // Popup titles
      this.jobTitle = document.getElementById("job-application-title")
      this.spontaneousTitle = document.getElementById("spontaneous-application-title")
      // Job name input
      this.jobInput = document.querySelector("[data-fui-id=\"formJobApplication-jobTitle\"]")
      this.jobInputValue = this.jobInput ? this.jobInput.value : null
   }

   /**
    * Updates the job title and input value to the current page entry
    */
   updateJobTitle () {
      if (isDebug) console.log("job:updateJobTitle")

      const titleElement = document.querySelector("[data-job-title]")
      const title = titleElement?.getAttribute("data-job-title") ?? ""

      console.log(this.spontaneousTitle)

      if (this.spontaneousTitle) this.spontaneousTitle.style.display = "none"
      this.jobTitle.style.display = title ? "block" : "none"

      if (title) {
         this.jobTitle.children[0].innerText = title
         this.jobInput.value = title
      }
   }

   /**
    * Resets to spontaneous application title and input value
    */
   resetJobTitle () {
      if (isDebug) console.log("job:resetJobTitle")

      if (this.jobInput) this.jobInput.value = this.spontaneousTitle.textContent ?? ""
      if (this.jobTitle) this.jobTitle.style.display = "none"
      if (this.spontaneousTitle) this.spontaneousTitle.style.display = "block"
   }

   unload () {
      if (isDebug) console.log("job:unload")

      if (this.jobInput) this.jobInput.value = this.jobInputValue
   }
}
