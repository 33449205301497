/* eslint-disable comma-dangle */
/*
--------------------------------------------------------------------------
  SWUP PARAMS
--------------------------------------------------------------------------
*/

import { isDebug } from "../utils"

import Swup from "swup"
import SwupDebugPlugin from "@swup/debug-plugin"
import SwupGtmPlugin from "@swup/gtm-plugin"
// import SwupScriptsPlugin from "@swup/scripts-plugin"

export default class {
   init () {
      if (isDebug) console.log("swup:init")

      const containers = ["#swup", "#language-switcher"]
      const existingContainers = []
      for (let i = 0; i < containers.length; i++) {
         const el = containers[i]
         if (document.querySelector(el)) {
            existingContainers.push(el)
         }
      }
      const plugins = [
         // new SwupScriptsPlugin({
         //    head: false,
         //    body: true,
         // }),
      ]
      if (isDebug) {
         plugins.push(new SwupDebugPlugin())
      } else {
         plugins.push(new SwupGtmPlugin())
      }

      this.swup = new Swup({
         containers: existingContainers,
         cache: true,
         animationSelector: ".transition-swup",
         ignoreVisit: (url, { el } = {}) => (
            el?.matches("[data-no-swup], [href$=\"contact\"], [target=\"_blank\"], [href*=\"actions/debug/\"]")
         ),
         animateHistoryBrowsing: true,
         skipPopStateHandling: function () {
            // Skip prev/next navigation transition
            return false
         },
         plugins: plugins
      })
   }

   destroy () {
      if (isDebug) console.log("swup:destroy")
      this.swup.destroy()
   }
}
