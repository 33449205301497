/* eslint-disable comma-dangle */
/*
--------------------------------------------------------------------------
  SWIPER PARAMS
--------------------------------------------------------------------------
*/

import { isDebug } from "../utils"

// import Swiper Modules - only the ones you will use
import Swiper, { A11y, FreeMode } from "swiper"

// import Core Swiper core SCSS
import "swiper/css"
// import "swiper/css/pagination";

// Init the modules
Swiper.use([A11y, FreeMode])

export default class {
   init () {
      if (isDebug) console.log("swiper:init")
      if (document.querySelector(".real-slider")) {
         this.reals = new Swiper(".real-slider", {
            slidesPerView: "auto",
            spaceBetween: 45,
            grabCursor: false,
            speed: 750,
            loop: false,
            autoHeight: false,
            slideToClickedSlide: true,
            preloadImages: false, // just add it to make it work with 3e joueur lazyload function
            slideClass: "slide", // better control on slide class and avoid flash on js load
            observer: true,
            freeMode: { enabled: true },
            breakpoints: {
               // when window width is >= 768px
               768: {
                  spaceBetween: 85
               },
            },
            on: {
               init: function () {
                  window.dispatchEvent(new Event("resize"))
               }
            }
         })
      }
   }

   destroy () {
      if (isDebug) console.log("swiper:destroy")
      if (document.querySelector(".real-slider") && this.reals) this.reals.destroy()
   }
}
