/* eslint-disable comma-dangle */
// Utilities
import { html, body, scrollContainer, isDebug } from "./utils"
import "./accessibility/focus.js"
// Modules
import Scroll from "./modules/scroll"
import Swup from "./modules/swup"
import Cursor from "./modules/cursor"
import Carousel from "./modules/swiper"
import Popup from "./modules/popup"
import Thumbnails from "./modules/thumbnails"
import Team from "./modules/team"
import Job from "./modules/job"
import Sliding from "./modules/sliding"
import {
   SlidingNav,
   Accordions,
   MultipleFilters,
   Modal,
} from "@overdog/fn"

// Modules

// Locomotive-scroll
const scroll = new Scroll()
// Swup
const swup = new Swup()
// Cursor
const cursor = new Cursor()
// Swiper
const carousel = new Carousel()
// Popup
const popup = new Popup()
// Thumbnails
const thumbnails = new Thumbnails()
// Team
const team = new Team()
// Job
const job = new Job()
// Sliding parallax titles
const sliding = new Sliding()

// Mutation observer
const observer = new MutationObserver(function (mutations) {
   // Loop through the mutations
   mutations.forEach(function (mutation) {
   // Check if the mutation type is "childList"
      if (mutation.type === "childList" && !body.hasAttribute("data-fn-nav-open")) {
      // Trigger a "resize" event on the window object
         window.dispatchEvent(new Event("resize"))
         if (isDebug) console.log("MutationObserver:change")
      }
   })
})

let accordions, fileInputs

// Scroll related functions
function updateScroll () {
   setTimeout(() => {
      scroll.update()
   }, 400)
}
function pauseScroll () {
   scroll.stop()
}
function resumeScroll () {
   scroll.start()
}
// Forms related handlers
function updateFilePath (e) {
   const file = e.target.files[0]
   const fileName = file ? file.name.replace(/^(?:.*[\\\/])?(.*)$/, "$1") : ""

   e.target.closest("[data-field-type=file-upload]").querySelector("label").innerHTML = fileName
}
// Modals related handlers
function showModal (e) {
   const bgClass = e.currentTarget.getAttribute("data-modal-bg") || "gray"

   body.setAttribute("data-fn-body-modal-is-open", true)
   body.classList.add("fanboy-is-" + bgClass)

   window.dispatchEvent(new Event("scrollPause"))
}
// Modals related handlers
function hideModal (e) {
   body.removeAttribute("data-fn-body-modal-is-open")
   body.classList.remove("fanboy-is-gray", "fanboy-is-yellow")

   window.dispatchEvent(new Event("scrollResume"))
}

// Links
const links = document.querySelectorAll("#navbar a[href*=\"" + window.location.hostname + "\"], #footer a[href*=\"" + window.location.hostname + "\"]")

// ██╗███╗   ██╗██╗████████╗
// ██║████╗  ██║██║╚══██╔══╝
// ██║██╔██╗ ██║██║   ██║
// ██║██║╚██╗██║██║   ██║
// ██║██║ ╚████║██║   ██║
// ╚═╝╚═╝  ╚═══╝╚═╝   ╚═╝
// Script run after native page load (everything that's outside swup)
function init () {
   if (isDebug) console.log("init()")
   // Loaded classes
   html.classList.add("is-loaded")
   html.classList.remove("is-loading", "is-animating")

   // Init Swup
   swup.init()
   // Swup hooks
   swup.swup.hooks.on("animation:out:start", unload) // Unload scripts related to the previous page content that has no immediate visual impact
   swup.swup.hooks.on("animation:out:end", kill) // Fully kill everything related to the previous content that had a visual impact on the page
   swup.swup.hooks.on("content:replace", load) // Load scripts related to the new page content
   swup.swup.hooks.on("link:self", function () {
      console.log("link:self")
      if (!body.hasAttribute("data-fn-nav-open")) {
         scroll.scrollTo("top")
      } else {
         body.removeAttribute("data-fn-nav-open")
      }
   })

   // Init cursor follower (fanboy)
   cursor.init()

   // Sliding Menu Panel
   new SlidingNav("#nav-buttons-group", {
      delayBetweenOpenings: 0,
      backTransition: ""
   }).init()

   load()

   /**
   --------------------------------------------------------------------------
   Funny things
   --------------------------------------------------------------------------
   */
   // Prospek ASCII name in console
   if (!isDebug) {
      console.log(
         " ___                      _    \n" +
         "| _ \\_ _ ___ ____ __  ___| |__ \n" +
         "|  _/ '_/ _ (_-< '_ \\/ -_) / / \n" +
         "|_| |_| \\___/__/ .__/\\___|_\\_\\ \n" +
         "               |_|             \n"
      )
   }
}

window.onload = init

// Fade out content on page refresh
window.onbeforeunload = function (e) {
   if (
      e.target.activeElement &&
      (typeof e.target.activeElement.href === "undefined" ||
      (e.target.activeElement.href.indexOf("tel:") === -1 &&
      e.target.activeElement.href.indexOf("mailto:") === -1))
   ) {
      // Only when it is not a phone or mail link
      html.classList.add("is-animating")
      unload()
      job.unload()
   }
}

// ██╗      ██████╗  █████╗ ██████╗
// ██║     ██╔═══██╗██╔══██╗██╔══██╗
// ██║     ██║   ██║███████║██║  ██║
// ██║     ██║   ██║██╔══██║██║  ██║
// ███████╗╚██████╔╝██║  ██║██████╔╝
// ╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚═════╝
// Script run at every new page load including native page load and swup page transitions
function load () {
   if (isDebug) console.log("load()")
   // Init Locomotive-scroll
   scroll.init()
   // Listen to popup's event for scroll pause
   window.addEventListener("scrollPause", pauseScroll)
   window.addEventListener("scrollResume", resumeScroll)

   // Current state on navigation links
   for (let i = 0; i < links.length; i++) {
      const el = links[i]
      const url = new URL(el.href)
      // Remove current state on links
      el.removeAttribute("data-is-current")

      // Match parent or current page and set it to current state
      if (
         url.host === window.location.host &&
         window.location.pathname !== "/" &&
         window.location.pathname !== "/en" &&
         window.location.pathname.indexOf(url.pathname) > -1
      ) {
         el.setAttribute("data-is-current", true)
      }
   }

   /**
   --------------------------------------------------------------------------
   Modals
   --------------------------------------------------------------------------
   */
   // Form modals
   if (document.querySelector("[data-fn-modal-button]")) {
      new Modal("[data-fn-modal-button]", {}).init()

      const modalButtons = document.querySelectorAll("[data-fn-modal-button]")
      const modalCloseButtons = document.querySelectorAll("[data-fn-modal-close]")

      for (let i = 0; i < modalButtons.length; i++) {
         modalButtons[i].addEventListener("click", showModal)
      }
      for (let i = 0; i < modalCloseButtons.length; i++) {
         modalCloseButtons[i].addEventListener("click", hideModal)
      }
   }

   /**
   --------------------------------------------------------------------------
   Accordions
   --------------------------------------------------------------------------
   */
   if (document.querySelector(".accordions-group-wrapper")) {
      new Accordions(".accordions-group-wrapper", {
         alternateMode: true,
      }).init()

      accordions = document.querySelectorAll("[data-fn-accordion] button")

      for (let i = 0; i < accordions.length; i++) {
         accordions[i].addEventListener("click", updateScroll)
      }
   }

   /**
   --------------------------------------------------------------------------
   Swiper
   --------------------------------------------------------------------------
   */
   if (document.querySelector(".swiper-container")) {
      carousel.init()
   }

   /**
   --------------------------------------------------------------------------
   Filters
   --------------------------------------------------------------------------
   */
   if (document.querySelector("#filter-wrapper-multiple")) {
      new MultipleFilters("#filter-wrapper-multiple", {
         divIds: ["listing"]
         // clearAllButtonId: "clear-all"
      }).init()
   }

   /**
   --------------------------------------------------------------------------
   Custom popups (Ideas, news)
   // TODO: Refactor with Overdog/fn/modals
   --------------------------------------------------------------------------
   */
   if (document.querySelector("[data-fn-popup]")) {
      popup.init()
   }

   /**
   --------------------------------------------------------------------------
   Thumbnails on hover (Projects archive)
   --------------------------------------------------------------------------
   */
   if (document.querySelector("[data-fn-project]")) {
      thumbnails.init()
   }

   /**
   --------------------------------------------------------------------------
   Info on hover (About team)
   --------------------------------------------------------------------------
   */
   if (document.querySelector("[data-fn-individual]")) {
      team.init()
   }

   /**
   --------------------------------------------------------------------------
   Update job application popup title and input value
   --------------------------------------------------------------------------
   */
   if (document.querySelector("[data-job-title]")) {
      job.updateJobTitle()
   } else {
      job.resetJobTitle()
   }

   /**
   --------------------------------------------------------------------------
   Sliding parallax titles
   --------------------------------------------------------------------------
   */
   if (document.querySelector(".parallax-track")) {
      sliding.init()
   }

   /**
   --------------------------------------------------------------------------
   Mutation observer to resize scroll container on content change
   --------------------------------------------------------------------------
   */
   // Start observing changes to the body element
   observer.observe(scrollContainer, {
      childList: true,
      subtree: true
   })

   /**
   --------------------------------------------------------------------------
   Form file input path display
   --------------------------------------------------------------------------
   */
   if (document.querySelector(".formie")) {
      fileInputs = document.querySelectorAll(".formie input[type=file]")
      for (let i = 0; i < fileInputs.length; i++) {
         const el = fileInputs[i]
         el.addEventListener("change", updateFilePath)
      }
   }
}

// ██╗   ██╗███╗   ██╗██╗      ██████╗  █████╗ ██████╗
// ██║   ██║████╗  ██║██║     ██╔═══██╗██╔══██╗██╔══██╗
// ██║   ██║██╔██╗ ██║██║     ██║   ██║███████║██║  ██║
// ██║   ██║██║╚██╗██║██║     ██║   ██║██╔══██║██║  ██║
// ╚██████╔╝██║ ╚████║███████╗╚██████╔╝██║  ██║██████╔╝
//  ╚═════╝ ╚═╝  ╚═══╝╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚═════╝
// Script run at the start of the out page animation
function unload () {
   if (isDebug) console.log("unload()")
   // if (typeof scroll.scrollTo !== "undefined") scroll.scrollTo("top")
   if (scroll) {
      scroll.unload()

      window.removeEventListener("scrollPause", pauseScroll)
      window.removeEventListener("scrollResume", resumeScroll)
   }

   // Stop the observer from observing
   observer.disconnect()

   // Accordions
   if (accordions) {
      for (let i = 0; i < accordions.length; i++) {
         accordions[i].removeEventListener("click", updateScroll)
      }
   }
   // File inputs
   if (fileInputs) {
      for (let i = 0; i < fileInputs.length; i++) {
         fileInputs[i].removeEventListener("change", updateFilePath)
      }
   }
   // Filters
   const filters = document.getElementById("#filter-wrapper-multiple")
   if (filters) {
      const labels = document.getElementById("filter-wrapper-multiple").querySelectorAll("input[type=radio] + label")
      for (let i = 0; i < labels.length; i++) {
         labels[i].removeEventListener("click")
      }
   }
   // Modal
   if (document.querySelector("[data-fn-modal-box][data-fn-is-open]")) {
      document.querySelector("[data-fn-is-open] [data-fn-modal-close]").dispatchEvent(new Event("click"))
      hideModal()
   }
   // Popup
   if (document.querySelector("[data-fn-popup-is-open]")) {
      popup.closePopup()
   }
   // Thumbnails
   if (document.querySelector("[data-fn-project]")) {
      thumbnails.unload()
   }
   // Close menu
   if (body.hasAttribute("data-fn-nav-open")) {
      document.querySelector("[data-fn-nav-button=\"" + body.getAttribute("data-fn-nav-open") + "\"]").dispatchEvent(new Event("click"))
   }
}

// ██╗  ██╗██╗██╗     ██╗
// ██║ ██╔╝██║██║     ██║
// █████╔╝ ██║██║     ██║
// ██╔═██╗ ██║██║     ██║
// ██║  ██╗██║███████╗███████╗
// ╚═╝  ╚═╝╚═╝╚══════╝╚══════╝
// Script run at the end of the out page animation
function kill () {
   if (isDebug) console.log("kill()")
   if (scroll) scroll.destroy()
   if (carousel) carousel.destroy()

   // Job title
   if (document.querySelector("[data-job-title]")) {
      job.resetJobTitle()
   }
   // Popup
   if (document.querySelector("button[data-fn-popup]")) {
      popup.destroy()
   }
   // Sliding parallax title
   if (document.querySelector(".parallax-track")) {
      sliding.unload()
   }
}
