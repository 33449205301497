const html = document.documentElement
const body = document.body
const nav = document.getElementById("navbar")
const menu = document.getElementById("burger-menu")
const scrollContainer = document.getElementById("scroll-container")
const scrollInstance = null
const footer = document.getElementById("footer")
const isDebug = html.hasAttribute("data-debug")

export { html, body, nav, menu, scrollContainer, scrollInstance, footer, isDebug }
