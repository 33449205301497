/* eslint-disable comma-dangle */
/*
--------------------------------------------------------------------------
  CUSTOM THUMBNAILS ON HOVER
--------------------------------------------------------------------------
*/

import { isDebug } from "../utils"

export default class {
   init () {
      if (isDebug) console.log("popup:init")

      this.section = document.getElementById("archive")
      this.listing = document.getElementById("listing")
      this.container = document.getElementById("real-thumbs")
      this.track = this.container.querySelector(".real-track")

      this.initThumbnails()
   }

   initThumbnails () {
      this.listing.addEventListener("mousemove", this.handleMouseMove)
      this.listing.addEventListener("mouseleave", this.handleMouseLeave)
   }

   handleMouseMove (e) {
      const section = this.section ?? document.getElementById("archive")
      this.section = section

      const container = this.container ?? document.getElementById("real-thumbs")
      this.container = container
      this.container.setAttribute("data-thumbs-is-active", true)

      const track = this.track ?? this.container.querySelector(".real-track")
      this.track = track

      const link = e.target.closest(".archived-project")
      if (link) {
         const slug = link.getAttribute("data-fn-project")
         const image = document.querySelector("#real-thumbs [data-fn-project=\"" + slug + "\"]")
         const index = Array.from(image.parentNode.children).indexOf(image)

         // Slide image content
         const currentTrackStyle = track.style.transform
         const newTrackStyle = "translateY(-" + (100 / track.childElementCount * index) + "%)"
         if (currentTrackStyle !== newTrackStyle) track.style.transform = newTrackStyle

         // Position the container on screen
         const sectionPos = section.getBoundingClientRect()
         const linkPos = link.getBoundingClientRect()
         const top = linkPos.top + link.offsetHeight / 2 - sectionPos.top
         const left = linkPos.left + link.offsetWidth * 0.55 - sectionPos.left

         const currentContainerLeft = container.style.left
         const newContainerLeft = left + "px"
         if (currentContainerLeft !== newContainerLeft) container.style.left = newContainerLeft

         const currentContainerTop = container.style.top
         const newContainerTop = top + "px"
         if (currentContainerTop !== newContainerTop) container.style.top = newContainerTop
      }
   }

   handleMouseLeave (e) {
      const container = this.container ?? document.getElementById("real-thumbs")
      this.container = container
      container.removeAttribute("data-thumbs-is-active")
   }

   unload () {
      if (isDebug) console.log("popup:unload")

      const listing = this.listing ?? this.container.getElementById("listing")
      this.listing = listing

      listing.removeEventListener("mousemove", this.handleMouseMove)
      listing.removeEventListener("mouseleave", this.handleMouseLeave)
   }
}
