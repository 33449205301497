/* eslint-disable comma-dangle */
/*
--------------------------------------------------------------------------
  LOCOMOTIVE SCROLL PARAMS AND CUSTOM METHODS
--------------------------------------------------------------------------
*/

import { body, nav, scrollContainer, isDebug } from "../utils"
import LocomotiveScroll from "locomotive-scroll"

/**
 * @author Maxime Doré
 */
export default class {
   /**
    * Initializes the scroll container with Locomotive on scroll/call listeners
    */
   init () {
      if (isDebug) console.log("locomotive-scroll:init")

      this.scroll = new LocomotiveScroll({
         el: scrollContainer,
         smooth: true,
         touchMultiplier: 3,
         smartphone: { smooth: false, breakpoint: 768 },
         tablet: { smooth: true },
         getDirection: true,
         reloadOnContextChange: true
      })

      // Calls methods of the in-view elements with the data-scroll-call attribute
      this.scroll.on("call", (func, way, obj) => {
         if (typeof this[func] === "function") {
            this[func](way, obj)
         } else if (Array.isArray(func)) {
            func.forEach((f) => {
               if (typeof this[f] === "function") {
                  this[f](way, obj)
               }
            })
         }
      })

      this.scroll.on("scroll", (args) => {
         // console.log(args.scroll)

         // TODO: Optimize on-scroll behaviors by debouncing/throttling outside of on:scroll event

         // Update nav size and hidden state depending on scroll position/direction
         this.updateNavState(args.scroll.y, args.direction)

         // Homepage mask animation on scroll
         this.updateMaskAnimation(args.scroll.y, args.currentElements)

         // In-view fade sections
         this.updateBgFadeSections(args.scroll.y, args.currentElements)
      })

      // Update to apply states prior to scrolling
      this.update()

      if (window.location.hash) {
         this.scrollTo(document.querySelector(window.location.hash), { duration: 0, offset: -80 })
      } else {
         this.scrollTo(1, { duration: 0 })
      }

      window.addEventListener("resize", () => this.update)
   }

   /**
    * Updates nav classes based off scroll position and direction. Hides the nav when scrolling down and shows it when scrolling up.
    * @param {int} y
    * @param {string} direction
    */
   updateNavState (y = 0, direction = "up") {
      const newNavClass = y > 100 ? (direction === "down" ? "is-going-down" : "is-going-up") : null

      if (newNavClass !== this.prevNavClass) {
         if (newNavClass) {
            body.classList.add("nav-is-smaller")
            nav.classList.add(newNavClass)
            nav.classList.remove(this.prevNavClass)
         } else {
            body.classList.remove("nav-is-smaller")
            nav.classList.remove(this.prevNavClass)
         }

         this.prevNavClass = newNavClass
      }
   }

   /**
    * Custom mask transition of the transform style. Used for ellipse animation. Also handles sliding margins animation on scroll.
    * @param {int} y
    * @param {object} currentElements
    */
   updateMaskAnimation (y = 0, currentElements = {}) {
      const update = () => {
         if (typeof currentElements["home-mask"] === "object") {
            const mask = currentElements["home-mask"]
            const progress = mask.progress
            mask.el.style.transform = "rotate(" + progress * 15 + "deg) scale(" + (1 + progress / 3) + ") translateY(-" + progress * 2 + "vw)"
         }

         if (typeof currentElements["section-margin"] === "object") {
            const header = currentElements["section-margin"]
            const navHeight = nav.clientHeight / window.innerHeight
            const treshold = window.innerWidth * 0.05
            let adjustedProgress = (y - navHeight) * 0.06
            if (adjustedProgress > treshold) adjustedProgress = treshold
            header.el.style.boxShadow = "inset 0 0 0 " + adjustedProgress + "px var(--tw-shadow-color)"
         }
      }

      // Use requestAnimationFrame for smoother animations
      if (!this.maskAnimationFrame) {
         this.maskAnimationFrame = requestAnimationFrame(() => {
            update()
            this.maskAnimationFrame = null
         })
      }
   }

   /**
    * Updates the background and text colors of the document based off the colorless section that takes up most of the viewport.
    * @param {int} y
    * @param {object} currentElements
    */
   updateBgFadeSections (y = 0, currentElements = {}) {
      const update = () => {
         const sectionsFade = Object.values(currentElements).filter(obj => obj.call === "updateColors")
            .sort((a, b) => Math.abs((a.bottom - a.top) / 2 - window.innerHeight / 2 - y) - Math.abs((b.bottom - b.top) / 2 - window.innerHeight / 2 - y))

         if (sectionsFade.length) {
            const bg = sectionsFade[0].el.getAttribute("data-bg") || "white"
            const color = sectionsFade[0].el.getAttribute("data-color") || "dark"

            this.reset(false, false, false, true)
            body.classList.add("bg-" + bg, "text-" + color)
         }
      }

      // Use requestAnimationFrame for smoother animations
      if (!this.bgAnimationFrame) {
         this.bgAnimationFrame = requestAnimationFrame(update)
         this.bgAnimationFrame = null
      }
   }

   /**
    * Locomotive scrollTo method
    * @param {*} target keyword, value (in pixels) or element to scroll to
    * @param {Object} options
    */
   scrollTo (target, options = {}) {
      if (isDebug) console.log("locomotive-scroll:scrollTo")
      if (this.scroll && typeof this.scroll.scrollTo !== "undefined") this.scroll.scrollTo(target, options)
   }

   updateColors (way, obj) {
      // if (isDebug) console.log("locomotive-scroll:updateColors")
      // TODO: Use only a method for better performance instead of using the scroll event
      // let el = obj.el

      // if (way === "exit") {
      //    el = this.bgFade[0].el
      // }
      // const bg = el.getAttribute("data-bg") ?? "white"
      // const color = el.getAttribute("data-color") ?? "dark"

      // if (way === "enter") {
      //    body.classList.remove("bg-white", "bg-dark", "bg-yellow", "text-white", "text-dark", "text-yellow")
      //    body.classList.add("bg-" + bg, "text-" + color)

      // } else if (this.bgFade.length === 0) {
      //    body.classList.remove("bg-white", "bg-dark", "bg-yellow", "text-white", "text-dark", "text-yellow")
      // }
   }

   /**
    * Resets the state of the elements specified as parameters
    * @param {boolean} navSize
    * @param {boolean} navColor
    * @param {boolean} navState
    * @param {boolean} bodyColor
    */
   reset (navSize, navColor, navState, bodyColor) {
      // if (isDebug) console.log("locomotive-scroll:reset")

      if (navSize) body.classList.remove("nav-is-smaller")
      if (navColor) body.classList.remove("nav-is-light")
      if (navState) nav.classList.remove("is-going-down")
      if (bodyColor) {
         body.classList.remove("bg-white", "bg-dark", "bg-yellow", "text-white", "text-dark", "text-yellow")
         nav.classList.remove("bg-white", "bg-dark", "bg-yellow", "text-white", "text-dark", "text-yellow")
      }
   }

   /**
    * Update the scroll container (height) Useful when the height of the content has changed
    */
   update () {
      if (isDebug) console.log("locomotive-scroll:update")
      this.scroll.update()
   }

   /**
    * Prevents scrolling in the scroll container
    */
   stop () {
      if (isDebug) console.log("locomotive-scroll:stop")
      this.scroll.stop()
   }

   /**
    * Resumes scrolling in the scroll container
    */
   start () {
      if (isDebug) console.log("locomotive-scroll:start")
      this.scroll.start()
   }

   /**
    * Remove event listeners assigned to the scroll container
    */
   unload () {
      if (isDebug) console.log("locomotive-scroll:unload")
      // Reset body and nav colors
      this.reset(true, true, false, true)
      // Remove listeners
      window.removeEventListener("resize", this.update)
   }

   /**
    * Destroy the scroll instance
    */
   destroy () {
      if (isDebug) console.log("locomotive-scroll:destroy")
      // Reset nav state
      this.reset(true, true, true, true)
      // Destroy instance
      this.scroll.destroy()
   }
}
