/* eslint-disable comma-dangle */
/*
--------------------------------------------------------------------------
  CURSOR FOLLOWER (FANBOY) PARAMS
--------------------------------------------------------------------------
*/

import { isDebug } from "../utils"

export default class {
   constructor () {
      this.fanboy = document.querySelector(".fanboy")
      this.animationId = null
   }

   init () {
      if (isDebug) console.log("cursor:init")

      this.isMouseDevice = window.matchMedia("(hover: hover)").matches

      if (this.isMouseDevice) {
         document.addEventListener("mousemove", this.handleMouseMove.bind(this))
      }
   }

   handleMouseMove (e) {
      // if (isDebug) console.log("cursor:handleMouseMove")
      cancelAnimationFrame(this.animationId)
      this.animationId = requestAnimationFrame(() => {
         if (window.innerWidth >= 768 && this.fanboy) {
            const mouseX = e.clientX
            const mouseY = e.clientY
            this.fanboy.style.transform = `translate3d(${mouseX}px, ${mouseY}px, 0)`

            const target = e.target
            const customCursorClass = this.getCustomCursorClass(target)
            this.fanboy.className = `fanboy fanboy-is-stalking ${customCursorClass}`
         } else if (this.fanboy) {
            this.fanboy.classList.remove("fanboy-is-stalking", "fanboy-cursor-horizontal-scroll", "fanboy-cursor-none", "fanboy-is-hovering", "fanboy-is-white", "fanboy-is-dark", "fanboy-is-yellow")
         }
      })
   }

   getCustomCursorClass (target) {
      const cursorClasses = {
         "a, button, label": "fanboy-is-hovering",
         ".fanboy-cursor-horizontal-scroll": "fanboy-cursor-horizontal-scroll",
         ".fanboy-cursor-none": "fanboy-cursor-none",
         ".section.bg-yellow": "fanboy-is-white",
         ".section.bg-dark, .section.bg-white, .section.bg-gray": "fanboy-is-yellow",
      }

      for (const selector in cursorClasses) {
         if (target && target.closest(selector)) {
            return cursorClasses[selector]
         }
      }

      return ""
   }

   unload () {
      if (isDebug) console.log("cursor:unload")

      if (this.fanboy) {
         this.fanboy.classList.remove("fanboy-is-stalking")
         this.fanboy.style.top = ""
         this.fanboy.style.left = ""
         this.fanboy = null
      }

      document.removeEventListener("mousemove", this.handleMouseMove)
   }
}
